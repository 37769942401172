import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  methods: {
    validateLineItems(param) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let data = param.lineitems;
          if (
            _this.lodash.isEmpty(data) === false &&
            _this.lodash.isArray(data)
          ) {
            let errors = [];
            let productType = ["service", "package"];
            for (let i = 0; i < data.length; i++) {
              try {
                if (!data[i].product) {
                  errors.push("Item is required.");
                } else if (
                  !data[i].product_type ||
                  _this.lodash.includes(productType, data[i].product_type) ===
                    false
                ) {
                  errors.push("Item type is required.");
                }
              } catch (error) {
                errors.push(error.message);
              }
            }
            if (_this.lodash.isEmpty(errors)) {
              resolve(data);
            } else {
              reject(errors.join(", "));
            }
          } else {
            reject("Line Item(s) data not found.");
          }
        } catch (error) {
          reject(error.message);
        }
      });
    },
  },
};
