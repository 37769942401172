import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import {
  LineItemEventBus,
  LineItemCalculationEventBus,
} from "@/core/lib/line-item/line.item.lib";
import {
  PUT,
  POST,
  GET,
  QUERY,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import ValidateInvoiceMixin from "@/core/lib/invoice/validate.invoice.mixin";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin, ValidateInvoiceMixin],
  data() {
    return {
      paymentDueList: [],
      customFieldDialog: false,
      customFields: new Array(),
      invoiceCustomFields: new Array(),
      isUpdateMode: false,
      isDuplicateMode: false,
      backForce: false,
      invoice: 0,
      quotationId: 0,
      duplicateInvoice: 0,
      invoiceArr: {},
      pageLoading: true,
      changeAttention: false,
      changeIssuedDate: false,
      barcodeDialog: false,
      lineItem: [],
      lineItemCalculation: {},
      termsCondition: null,
      noteAttachment: {},
      customer: {},
      property: {},
      billing: {},
      contact_person: {},
      barcodeSetting: {},
      updateData: {
        Details: new Object(),
        InvoiceLineItem: new Array(),
        InvoiceLineItemCalculation: new Object(),
        InvoiceTermsConditions: new Object(),
        InvoiceNotesAttachment: new Object(),
      },
      invoiceCreate: {
        reference: null,
        customer: 0,
        project: 0,
        property: 0,
        contact_person: 0,
        title: null,
        quotation: null,
        attention: null,
        admin_remark: null,
        client_remark: null,
        term_conditions: null,
        additional_remarks: null,
        discount_value: null,
        adjustment: null,
        discount_type: null,
        discount_value_type: null,
        notify_admin: null,
        notify_customer: null,
        issued_type: 0,
        payment_due: 2,
        invoice_date: moment().format("YYYY-MM-DD"),
        due_date: moment().format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    getQuotationDetail(param) {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "quotation/" + param,
        })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data) === false) {
            _this.updateData.Details = data;
            _this.updateData.InvoiceLineItemCalculation = {
              additional_remarks: data.additional_remarks,
              sub_total: data.sub_total,
              tax_amount: data.tax_amount,
              discount_value: data.discount_value,
              discount_type: data.discount_type,
              discount_value_type: data.discount_value_type,
              adjustment: data.adjustment,
              total: data.total,
            };

            _this.updateData.InvoiceTermsConditions = {
              term_conditions: data.term_conditions,
            };

            _this.updateData.InvoiceNotesAttachment = {
              admin_remark: data.admin_remark,
              client_remark: data.client_remark,
              notify_admin: data.notify_admin,
              notify_customer: data.notify_customer,
            };

            _this.invoiceCreate.reference = data.reference;
            _this.invoiceCreate.project = data.project;
            _this.invoiceCreate.customer = data.customer.customer;
            _this.invoiceCreate.contact_person =
              data.contact_person.contact_person;
            _this.invoiceCreate.title = data.quotation_title;
            _this.invoiceCreate.quotation = data.id;
            _this.invoiceCreate.attention = data.attention;
            _this.invoiceCreate.admin_remark = data.admin_remark;
            _this.invoiceCreate.client_remark = data.client_remark;
            _this.invoiceCreate.term_conditions = data.term_conditions;
            _this.invoiceCreate.additional_remarks = data.additional_remarks;
            _this.invoiceCreate.discount_value = data.discount_value;
            _this.invoiceCreate.adjustment = data.adjustment;
            _this.invoiceCreate.discount_type = data.discount_type;
            _this.invoiceCreate.discount_value_type = data.discount_value_type;
            _this.invoiceCreate.notify_admin = data.notify_admin;
            _this.invoiceCreate.notify_customer = data.notify_customer;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.getLineItems({ quotation: param });
        });
    },
    getLineItems(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item",
          data,
        })
        .then(async ({ data }) => {
          if (
            _this.lodash.isEmpty(data.rows) === false &&
            _this.lodash.isArray(data.rows)
          ) {
            let result = [];
            for (var i = 0; i < data.rows.length; i++) {
              result.push({
                id: undefined,
                maximum_rental: data.rows[i].maximum_rental,
                minimum_rental: data.rows[i].minimum_rental,
                modified_at: data.rows[i].modified_at,
                original_product: data.rows[i].original_product,
                product: data.rows[i].product,
                quantity: data.rows[i].quantity,
                description: data.rows[i].description,
                rate: data.rows[i].rate,
                sub_total: data.rows[i].sub_total,
                tax: data.rows[i].tax,
                tax_amount: data.rows[i].tax_amount,
                tax_value: data.rows[i].tax_value,
                total: data.rows[i].total,
              });
            }

            _this.updateData.InvoiceLineItem = result;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        });
    },
    setIssuedDate(param) {
      this.invoiceCreate.invoice_date = param;
    },
    setDueDate(param) {
      this.invoiceCreate.due_date = param;
    },
    updateBarcodeSetting() {
      this.barcodeDialog = false;
    },
    getAttributes(attributes) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "invoice/options/" + _this.invoice,
          data: {
            duplicate: +_this.isDuplicateMode,
            attribute: attributes,
            customer: _this.invoiceCreate.customer || null,
            contact_person: _this.invoiceCreate.contact_person || null,
            property: _this.invoiceCreate.property || null,
          },
        })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data) === false) {
            if (_this.lodash.isEmpty(data.invoice) === false) {
              _this.invoiceCreate = data.invoice;
              _this.updateData.InvoiceLineItemCalculation = data.invoice;
              _this.updateData.InvoiceTermsConditions = data.invoice;
              _this.updateData.InvoiceNotesAttachment = data.invoice;
              _this.updateData.InvoiceLineItem = data.invoice.line_items;
              _this.$store.dispatch(SET_BREADCRUMB, [
                {
                  title: "Invoice",
                  route: "invoice",
                },
                {
                  title: "Update",
                },
                {
                  title: _this.invoiceCreate.barcode,
                },
              ]);
            }

            if (_this.lodash.isEmpty(data.customer) === false) {
              _this.customer = data.customer;
            }

            if (_this.lodash.isEmpty(data.payment_terms) === false) {
              _this.paymentDueList = data.payment_terms;
            }

            if (_this.lodash.isEmpty(data.property) === false) {
              _this.property = data.property;
            }

            if (_this.lodash.isEmpty(data.billing) === false) {
              _this.billing = data.billing;
            }

            if (_this.lodash.isEmpty(data.contact_person) === false) {
              _this.contact_person = data.contact_person;
              _this.invoiceCreate.attention = data.contact_person.display_name;
            }

            if (_this.lodash.isEmpty(data.barcode) === false) {
              _this.invoiceCreate.barcode = data.barcode;
            }

            if (_this.lodash.isEmpty(data.barcode_setting) === false) {
              _this.barcodeSetting = data.barcode_setting;

              _this.updateData.InvoiceTermsConditions = {
                term_conditions: _this.barcodeSetting.terms_conditions,
              };

              _this.updateData.InvoiceNotesAttachment = {
                admin_remark: _this.barcodeSetting.admin_note,
                client_remark: _this.barcodeSetting.client_note,
              };
            }

            if (_this.lodash.isEmpty(data.custom_field) === false) {
              _this.customFields = data.custom_field;
            }
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    onSubmit(type) {
      const _this = this;
      if (!_this.$refs.invoiceForm.validate()) {
        return false;
      }

      _this.$store.dispatch(CLEAR_ERROR, {});
      const formData = {
        action: typeof type == "string" ? type : null,
        reference: _this.invoiceCreate.reference || null,
        project:
          _this.lodash.toSafeInteger(_this.invoiceCreate.project) || null,
        customer: _this.lodash.toSafeInteger(_this.invoiceCreate.customer) || 0,
        property: _this.lodash.toSafeInteger(_this.invoiceCreate.property) || 0,
        contact_person:
          _this.lodash.toSafeInteger(_this.invoiceCreate.contact_person) || 0,
        title: _this.invoiceCreate.title || null,
        quotation:
          _this.lodash.toSafeInteger(_this.invoiceCreate.quotation) || null,
        attention: _this.invoiceCreate.attention || null,
        admin_remark: _this.noteAttachment.admin_notes || null,
        client_remark: _this.noteAttachment.client_notes || null,
        term_conditions: _this.termsCondition || null,
        additional_remarks: _this.lineItemCalculation.description || null,
        tax: _this.lineItemCalculation.tax,
        tax_type: _this.lineItemCalculation.taxType,
        discount_value: _this.lodash.toSafeInteger(
          _this.lineItemCalculation.discountValue
        ),
        adjustment: _this.lineItemCalculation.adjustmentAmount,
        discount_type: _this.lineItemCalculation.discountType,
        discount_value_type: _this.lineItemCalculation.discountValueType,
        notify_admin:
          _this.lodash.toSafeInteger(_this.noteAttachment.notify_admin) || 0,
        notify_customer:
          _this.lodash.toSafeInteger(_this.noteAttachment.notify_customer) || 0,
        issued_type:
          _this.lodash.toSafeInteger(_this.invoiceCreate.issued_type) || 0,
        payment_due:
          _this.lodash.toSafeInteger(_this.invoiceCreate.payment_due) || 0,
        invoice_date: _this.invoiceCreate.invoice_date || null,
        due_date: _this.invoiceCreate.due_date || null,
        documents: _this.noteAttachment.documents || [],
        custom_field: _this.customFields || [],
      };

      if (!formData.issued_type || formData.issued_type === 0) {
        formData.invoice_date = null;
      }

      if (formData.payment_due != 5) {
        formData.due_date = null;
      }

      let REQUEST_TYPE = POST;
      let REQUEST_URL = "invoice";
      if (_this.invoice && _this.invoice > 0) {
        REQUEST_TYPE = PUT;
        REQUEST_URL = "invoice/" + _this.invoice;
      }

      _this
        .validateInvoice({
          invoice: formData,
        })
        .then(() => {
          _this.formLoading = true;
          _this.$store
            .dispatch(REQUEST_TYPE, {
              url: REQUEST_URL,
              data: formData,
            })
            .then((response) => {
              _this.invoice = response.data.id;
              _this
                .CreateLineItems({
                  type: "invoice",
                  parent: _this.invoice,
                  formData: _this.lineItem,
                })
                .then(() => {
                  _this.backForce = true;
                  _this.$router.push(
                    _this.getDefaultRoute("invoice.detail", {
                      params: {
                        id: _this.invoice,
                      },
                    })
                  );
                })
                .catch((error) => {
                  _this.backForce = false;
                  _this.logError(error);
                });
            })
            .finally(() => {
              _this.formLoading = false;
            });
        })
        .catch((error) => {
          ErrorEventBus.$emit("update:error", InitializeError(error));
        });
    },
  },
  watch: {
    quotationId(param) {
      if (param > 0) {
        this.getQuotationDetail(param);
      }
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Invoice",
        route: "invoice",
      },
      {
        title: "Create",
      },
    ]);

    _this.getAttributes([
      "customer",
      "contact_person",
      "property",
      "billing",
      "barcode",
      "barcode_setting",
      "custom_field",
      "payment_terms",
    ]);
  },
  created() {
    const _this = this;
    _this.invoiceCreate.project = _this.lodash.toSafeInteger(
      _this.$route.query.project
    );
    _this.invoiceCreate.customer = _this.lodash.toSafeInteger(
      _this.$route.query.customer
    );
    _this.invoiceCreate.contact_person = _this.lodash.toSafeInteger(
      _this.$route.query.contact_person
    );
    _this.invoiceCreate.property = _this.lodash.toSafeInteger(
      _this.$route.query.property
    );
    if (
      !_this.invoiceCreate.customer ||
      !_this.invoiceCreate.contact_person ||
      !_this.invoiceCreate.property
    ) {
      _this.$router.go(-1);
    }

    if (_this.$route.name == "invoice-update") {
      _this.invoice = _this.$route.params.id;
      if (!_this.invoice) {
        _this.$router.go(-1);
      }
      _this.isUpdateMode = true;
      _this.isDuplicateMode = false;
    }

    if (
      _this.$route.name == "admin.invoice.create" &&
      _this.$route.query.duplicate > 0
    ) {
      _this.duplicateInvoice = _this.$route.query.duplicate;
      _this.invoice = _this.$route.query.duplicate;
      _this.isDuplicateMode = true;
      _this.isUpdateMode = false;
    }
    _this.quotationId = _this.lodash.toSafeInteger(
      _this.$route.query.quotation
    );

    LineItemEventBus.$on("update:line-item", (argument) => {
      _this.lineItem = argument;
    });

    LineItemCalculationEventBus.$on(
      "update:line-item-calculation",
      (argument) => {
        _this.lineItemCalculation = argument;
      }
    );

    TermConditionEventBus.$on("update:term-condition", (argument) => {
      _this.termsCondition = argument;
    });

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      _this.noteAttachment = argument;
    });
  },
  computed: {
    isPageLoading() {
      return this.pageLoading || this.formLoading;
    },
    CityBillingName() {
      return this.lodash.isEmpty(this.billing.city) === false
        ? this.billing.city.name
        : null;
    },
    StateBillingName() {
      return this.lodash.isEmpty(this.billing.state) === false
        ? this.billing.state.name
        : null;
    },
    CountryBillingName() {
      return this.lodash.isEmpty(this.billing.country) === false
        ? this.billing.country.name
        : null;
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
  },
};
