<template>
  <div
    class="invoice-create create-form"
    v-if="getPermission('invoice:create')"
  >
    <v-form
      ref="invoiceForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-container fluid class="white-background main-create-page">
            <div class="p-5 pb-0">
              <v-row>
                <v-col cols="7" class="pt-0">
                  <v-skeleton-loader
                    v-if="pageLoading"
                    class="custom-skeleton custom-skeleton-full-width"
                    type="button"
                  ></v-skeleton-loader>
                  <h1 v-else class="form-title d-flex">
                    Invoice For
                    <span
                      class="m-0 form-title-create-link pl-2"
                      :class="{
                        'custom-nowrap-ellipsis': !lodash.isEmpty(customer),
                      }"
                      link
                    >
                      <template v-if="lodash.isEmpty(customer)"
                        >Client Name</template
                      >
                      <template v-else>{{ customer.display_name }}</template>
                      <v-icon
                        link
                        large
                        color="cyan"
                        class="mx-2"
                        v-if="lodash.isEmpty(customer) || isDuplicateMode"
                        >mdi-plus-circle-outline</v-icon
                      >
                    </span>
                  </h1>
                </v-col>
                <v-col cols="5" class="pt-0 text-right">
                  <v-btn
                    v-on:click="goBack"
                    :disabled="formLoading || pageLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="!formValid || formLoading || pageLoading"
                    :loading="formLoading"
                    class="mx-2 custom-bold-button white--text"
                    v-on:click="onSubmit"
                    color="cyan"
                  >
                    Save Invoice
                  </v-btn>
                  <v-menu
                    v-if="false && !isUpdateMode"
                    content-class="custom-menu-list"
                    transition="slide-y-transition"
                    bottom
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="!formValid || formLoading || pageLoading"
                        :loading="formLoading"
                        class="mx-2 custom-bold-button white--text"
                        color="cyan"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Save and... <v-icon right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link v-on:click="onSubmit('send_as_email')">
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue"
                            >mdi-email-send</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-500 font-size-14"
                          >Send as Email</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="false"
                        link
                        v-on:click="onSubmit('mark_as_paid')"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue"
                            >mdi-check-all</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-500 font-size-14"
                          >Mark as Paid</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <div v-bar class="p-5 pt-0 create-invoice-vbar">
              <v-row>
                <v-col cols="7" class="pb-0">
                  <v-container fluid>
                    <div>
                      <label class="font-weight-700 font-size-16"
                        >Invoice Subject</label
                      >
                      <v-text-field
                        v-model.trim="invoiceCreate.title"
                        dense
                        filled
                        color="cyan"
                        label="Subject"
                        :disabled="isPageLoading"
                        solo
                        flat
                        :rules="[
                          validateRules.required(
                            invoiceCreate.title,
                            'Invoice Subject'
                          ),
                          validateRules.minLength(
                            invoiceCreate.title,
                            'Invoice Subject',
                            1
                          ),
                          validateRules.maxLength(
                            invoiceCreate.title,
                            'Invoice Subject',
                            100
                          ),
                        ]"
                      ></v-text-field>
                    </div>
                    <div>
                      <v-row>
                        <v-col cols="4">
                          <v-skeleton-loader
                            v-if="pageLoading"
                            class="custom-skeleton custom-card-heading"
                            type="card-heading, text@5"
                          ></v-skeleton-loader>
                          <template v-else>
                            <label class="font-weight-700 font-size-16"
                              >Site address</label
                            >
                            <div v-if="lodash.isEmpty(billing) === false">
                              <p
                                v-if="billing.property_address"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ billing.property_address }}
                              </p>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="4">
                          <v-skeleton-loader
                            v-if="pageLoading"
                            class="custom-skeleton custom-card-heading"
                            type="card-heading, text@5"
                          ></v-skeleton-loader>
                          <template v-else>
                            <label class="font-weight-700 font-size-16"
                              >Service address</label
                            >
                            <div v-if="lodash.isEmpty(property) === false">
                              <p
                                v-if="property.property_address"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ property.property_address }}
                              </p>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="4">
                          <v-skeleton-loader
                            v-if="pageLoading"
                            class="custom-skeleton custom-card-heading"
                            type="card-heading, text@3"
                          ></v-skeleton-loader>
                          <template v-else>
                            <label class="font-weight-700 font-size-16"
                              >Contact details</label
                            >
                            <div
                              v-if="lodash.isEmpty(contact_person) === false"
                            >
                              <p
                                v-if="contact_person.display_name"
                                class="mb-0 font-size-16"
                              >
                                <template
                                  v-if="
                                    changeAttention || !invoiceCreate.attention
                                  "
                                >
                                  <v-text-field
                                    v-model.trim="invoiceCreate.attention"
                                    :rules="[
                                      validateRules.required(
                                        invoiceCreate.attention,
                                        'Attention'
                                      ),
                                      validateRules.minLength(
                                        invoiceCreate.attention,
                                        'Attention',
                                        1
                                      ),
                                      validateRules.maxLength(
                                        invoiceCreate.attention,
                                        'Attention',
                                        100
                                      ),
                                    ]"
                                    dense
                                    filled
                                    :disabled="isPageLoading"
                                    color="cyan"
                                    label="Attention"
                                    solo
                                    flat
                                    hide-details
                                    @keydown.esc="changeAttention = false"
                                    @keydown.enter="changeAttention = false"
                                    @keydown.tab="changeAttention = false"
                                    v-on:change="changeAttention = false"
                                  ></v-text-field>
                                </template>
                                <template v-else>
                                  <div class="d-flex">
                                    <p class="mb-0 text-ellipsis width-300px">
                                      {{ invoiceCreate.attention }}
                                    </p>
                                    <v-btn
                                      text
                                      small
                                      :disabled="isPageLoading"
                                      class="custom-bold-button"
                                      v-on:click="changeAttention = true"
                                      color="cyan"
                                    >
                                      Change
                                    </v-btn>
                                  </div>
                                </template>
                              </p>
                              <p
                                v-if="contact_person.primary_phone"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ contact_person.primary_phone }}
                              </p>
                              <p
                                v-if="contact_person.primary_email"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ contact_person.primary_email }}
                              </p>
                            </div>
                          </template>
                        </v-col>
                      </v-row>
                    </div>
                  </v-container>
                </v-col>
                <v-col cols="5" class="pb-0">
                  <v-container fluid>
                    <table class="width-100">
                      <tr>
                        <td
                          colspan="3"
                          class="font-weight-700 font-size-16 pb-2"
                        >
                          Invoice details
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-16 py-2" width="200">
                          Invoice number
                        </td>
                        <td
                          class="font-weight-700 font-size-16 pb-2"
                          width="150"
                          :colspan="isUpdateMode ? 2 : 1"
                        >
                          <v-skeleton-loader
                            v-if="pageLoading"
                            class="custom-skeleton"
                            type="text"
                          ></v-skeleton-loader>
                          <template v-else>{{
                            invoiceCreate.barcode
                          }}</template>
                        </td>
                        <td v-if="!isUpdateMode" class="font-size-16 pb-2">
                          <v-btn
                            text
                            small
                            :disabled="isPageLoading"
                            class="custom-bold-button"
                            v-on:click="barcodeDialog = true"
                            color="cyan"
                          >
                            Change
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-2 font-size-16">Reference #</td>
                        <td colspan="2" class="py-2">
                          <v-text-field
                            dense
                            color="cyan"
                            filled
                            :disabled="isPageLoading"
                            v-model.trim="invoiceCreate.reference"
                            label="Reference #"
                            :rules="[
                              validateRules.minLength(
                                invoiceCreate.reference,
                                'Reference #',
                                1
                              ),
                              validateRules.maxLength(
                                invoiceCreate.reference,
                                'Reference #',
                                100
                              ),
                            ]"
                            solo
                            flat
                            hide-details
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-2 font-size-16">Issued date</td>
                        <td colspan="2" class="py-2">
                          <div
                            v-if="invoiceCreate.issued_type == 1"
                            class="d-flex"
                          >
                            <DatePicker
                              :pageLoading.sync="isPageLoading"
                              solo
                              :placeholder="'Issued date'"
                              v-on:update:date-picker="setIssuedDate"
                            ></DatePicker>
                            <div class="my-auto mx-2">
                              <v-icon
                                link
                                color="red lighten-1"
                                v-on:click="invoiceCreate.issued_type = 0"
                                >mdi-close</v-icon
                              >
                            </div>
                          </div>
                          <v-btn
                            text
                            :disabled="isPageLoading"
                            class="custom-bold-button"
                            v-on:click="invoiceCreate.issued_type = 1"
                            color="cyan"
                            v-else
                          >
                            Date Sent
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-2 font-size-16">Payment due</td>
                        <td colspan="2" class="py-2">
                          <v-select
                            :items="paymentDueList"
                            dense
                            filled
                            solo
                            flat
                            hide-details
                            :disabled="isPageLoading"
                            v-model.trim="invoiceCreate.payment_due"
                            label="Payment due"
                            color="cyan"
                            item-text="text"
                            item-value="id"
                            item-color="cyan"
                          ></v-select>
                        </td>
                      </tr>
                      <tr v-if="invoiceCreate.payment_due == 5">
                        <td class="pb-2 font-size-16">Due date</td>
                        <td colspan="2" class="py-2">
                          <DatePicker
                            :placeholder="'Due date'"
                            solo
                            :pageLoading.sync="isPageLoading"
                            v-on:update:date-picker="setDueDate"
                          ></DatePicker>
                        </td>
                      </tr>
                      <template v-if="false">
                        <tr
                          v-for="(field, index) in customFields"
                          :key="index"
                          :class="{
                            'custom-border-bottom': customFields[index + 1],
                          }"
                        >
                          <td class="pb-2 font-size-16">
                            {{ field.field_name }}
                          </td>
                          <td colspan="2" class="py-2">
                            <CustomField
                              :field.sync="field"
                              :pageLoading.sync="isPageLoading"
                              :index.sync="index"
                            ></CustomField>
                          </td>
                        </tr>
                        <tr v-if="false && customFields.length < 5">
                          <td colspan="3">
                            <v-btn
                              :disabled="isPageLoading"
                              class="my-2 mr-2 custom-bold-button white--text"
                              color="cyan"
                              small
                              solo
                              flat
                              v-on:click="customFieldDialog = true"
                              ><strong class="font-size-14"
                                >Add Custom Field
                              </strong>
                            </v-btn>
                            <template v-if="customFieldDialog">
                              <CreateCustomField
                                :fieldModule="2"
                                v-on:update:custom-field="getCustomField"
                                v-on:close:custom-field="
                                  customFieldDialog = false
                                "
                              ></CreateCustomField>
                            </template>
                          </td>
                        </tr>
                      </template>
                    </table>
                  </v-container>
                </v-col>
                <v-col
                  cols="12"
                  class="pt-0"
                  v-if="getPermission('line-item:create')"
                >
                  <v-container fluid>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <h3
                          class="font-weight-700 custom-headline color-custom-blue"
                        >
                          Line Items
                        </h3>
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <v-row dense>
                          <v-col cols="12" class="pt-0 pb-0">
                            <InvoiceLineItem
                              isInvoice
                              :updateData.sync="updateData.InvoiceLineItem"
                              :details.sync="updateData.Details"
                              :pageLoading.sync="isPageLoading"
                            ></InvoiceLineItem>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-container>
                </v-col>
                <v-col cols="12">
                  <InvoiceTermsConditions
                    :updateData.sync="updateData.InvoiceTermsConditions"
                    :pageLoading.sync="isPageLoading"
                  ></InvoiceTermsConditions>
                </v-col>
                <v-col cols="12">
                  <InvoiceNotesAttachment
                    :updateData.sync="updateData.InvoiceNotesAttachment"
                    :pageLoading.sync="isPageLoading"
                  ></InvoiceNotesAttachment>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-form>
    <BarcodeSetting
      endpoint="invoice"
      :barcodeDialog="barcodeDialog"
      :dialogWidth="600"
      :barcodeSetting.sync="barcodeSetting"
      v-on:close:barcode-dialog="barcodeDialog = false"
    ></BarcodeSetting>
    <Dialog :commonDialog="routePreventDialog" :dialogWidth="600">
      <template v-slot:body>
        <v-layout>
          <v-flex md2 class="text-center">
            <!--begin::Svg Icon-->
            <inline-svg
              width="50"
              :src="$assetURL('media/custom-svg/attention-circle.svg')"
            />
            <!--end::Svg Icon-->
          </v-flex>
          <v-flex md10>
            <p class="font-weight-700">
              Are you sure you want to leave this page?
            </p>
            <v-layout>
              <v-flex>
                <v-btn
                  class="mr-2 custom-grey-border custom-bold-button white--text"
                  color="cyan"
                  small
                  v-on:click="stayOnPage"
                >
                  Stay on this page
                </v-btn>
                <v-btn
                  class="mx-2 custom-grey-border custom-bold-button"
                  small
                  v-on:click="leavePage"
                >
                  Leave this page
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </Dialog>
  </div>
</template>

<script>
import CreateInvoiceMixin from "@/core/lib/invoice/create.invoice.mixin";
import InvoiceLineItem from "@/view/pages/partials/Line-Item.vue";
import InvoiceTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import InvoiceNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CustomField from "@/view/pages/custom-field/Custom-Field.vue";
import CreateCustomField from "@/view/pages/custom-field/Create-Custom-Field.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

export default {
  mixins: [CreateInvoiceMixin],
  name: "invoice-create",
  title: "Create Invoice",
  data() {
    return {
      routePreventDialog: false,
    };
  },
  methods: {
    stayOnPage() {
      PreventDialogEventBus.$emit("stay-on-page", true);
    },
    leavePage() {
      PreventDialogEventBus.$emit("leave-page", true);
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
  components: {
    InvoiceLineItem,
    InvoiceTermsConditions,
    InvoiceNotesAttachment,
    BarcodeSetting,
    Dialog,
    DatePicker,
    CustomField,
    CreateCustomField,
  },
};
</script>
